import React from "react"
import styled from "styled-components"
import SyncLoader from "react-spinners/SyncLoader"

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
`

const SuspenseLoading = () => {
  return (
    <Root>
      <SyncLoader size={25} color="#693c5e" loading={true} />
    </Root>
  )
}

export default SuspenseLoading
